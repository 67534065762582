import '../../sass/main.scss';

$(function () {
    $('.open-menu').click(function (e) {
        e.preventDefault();
        $('nav').addClass('active');
    });

    // Header
    $(window).scroll(function () {
        if ($(window).scrollTop() > 70) {
            $('header').addClass('scrolled');
        } else {
            $('header').removeClass('scrolled');
        }
    });

    // Correct selects
    let Select = $('select');
    Select.addClass('custom-select');
    Select.addClass('custom-select-lg');
    Select.removeClass('form-control');

    let Back = $('.open-image-pop-back');

    $(document).on('click', '.open-image', function (e) {
        e.preventDefault();
        let This = $(this);
        let Code = This.data('open');
        Back.css('display', 'block');

        $('.open-image-pop').each(function () {
            $(this).css({'display': 'none'})
        });
        let popUp = $(`.open-image-pop[data-image="${Code}"]`);

        popUp.css({
            'display': 'block',
        });

        if (This.data('video')) {
            popUp.find('video').trigger('play');
        }

        $('.caption-ig-pop').trunk8({
            lines: 3
        });
    });

    $(document).on('click', '.open-image-pop-back', function (e) {
        e.preventDefault();
        $('.open-image-pop').each(function () {
            $(this).css({'display': 'none'})
        });
        $(this).css('display', 'none');
        $('video').trigger('pause');
    });
});